
import { Component, OnInit, } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InventaireService } from '../../shared/service/inventaire.service';

import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],

})
export class ImportModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ImportModalComponent>, private _inventaireService: InventaireService,
    private toastr: ToastrService) { }
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close({ event: 'annuler' });
  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
  upload() {
    this.progress = 0;

    this.currentFile = this.selectedFiles.item(0);
    this._inventaireService.upload(this.currentFile).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.message = event.body.message;
          this.dialogRef.close({ event: 'Succès' });
        }

      },
      err => {

        this.progress = 0;
        this.message = 'Impossible de télécharger le fichier!';
        this.currentFile = undefined;
        this.showError();
      });

    this.selectedFiles = undefined;
  }

  showError() {
    this.toastr.error('Erreur se produite lors de l import');
  }
}
